import { content } from './content';
export default {
  dpn: {
    id: 'wakefern',
    name: 'Wakefern',
  },
  storageKey: 'pbyt',
  offers: {
    offerTag: {
      production: process.env.REACT_APP_OFFER_TAG,
      local: process.env.REACT_APP_OFFER_TAG
    }
  },
  token: '', //captcha token
  images: {
    logo: 'shoprite-logo.png',
  },
  links: {
    register: 'https://profile.brands.wakefern.com/ShopRite/Register',
  },
  content: content,
};
